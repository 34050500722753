<template>
  <g :id="`Female56AvatarMobile_${uniqueIdSuffixA}`">
    <g>
      <circle style="fill: #d0d0d0" cx="68.5" cy="135.3" r="62.7" />
    </g>
    <g>
      <g>
        <path
          style="fill: #b5b4b5"
          d="M68.5,198.9c-35.1,0-63.6-28.5-63.6-63.6s28.5-63.6,63.6-63.6c35.1,0,63.6,28.5,63.6,63.6
				S103.6,198.9,68.5,198.9z M68.5,82.2c-29.3,0-53.1,23.8-53.1,53.1s23.8,53.1,53.1,53.1s53.1-23.8,53.1-53.1S97.8,82.2,68.5,82.2z
				"
        />
      </g>
    </g>
    <g>
      <defs>
        <circle
          id="SVGID_00000003099135688070316700000010216972879259863989_"
          cx="68.5"
          cy="135.3"
          r="50.9"
        />
      </defs>
      <clipPath id="SVGID_00000124133842340023643840000013529748132293004165_">
        <use
          xlink:href="#SVGID_00000003099135688070316700000010216972879259863989_"
          style="overflow: visible"
        />
      </clipPath>
      <g
        style="
          clip-path: url(#SVGID_00000124133842340023643840000013529748132293004165_);
        "
      >
        <path
          style="fill: #333333"
          d="M84.5,132.8c0,0-16.4,2-13.9,12.5h-4c2.6-10.5-13.9-12.5-13.9-12.5c-6-0.9-10.8,4.8-8.4,15.3
				c2.4,10.6,6.1,28.8,6.1,28.8c2.3-6.4,17-8.1,14.2-13.3h7.8c-2.8,5.2,11.9,6.9,14.2,13.3c0,0,3.7-18.1,6.1-28.7
				C95.3,137.6,90.4,131.9,84.5,132.8z"
        />
        <path
          style="fill: #4c4c4c"
          d="M57.3,107.1c-6.8,8.5-0.7,30.2-4.5,39.8c0,0,0,0,0,0l1.1-2.7c4.2-12.4,2.7-32.5,6.6-35.5
				c4.9-3.8,8.1-1.5,8.1-1.5S64.1,98.6,57.3,107.1z"
        />
        <path
          style="fill: #4c4c4c"
          d="M80,107.1c6.8,8.5,0.7,30.2,4.5,39.8c0,0,0,0,0,0l-1.1-2.7c-4.2-12.4-2.7-32.5-6.6-35.5
				c-4.9-3.8-8.1-1.5-8.1-1.5S73.2,98.6,80,107.1z"
        />
        <path
          style="fill: #404040"
          d="M51.5,179c-0.4,0-0.7-0.3-0.7-0.7v-14.7c0-0.4,0.3-0.6,0.7-0.6c0.4,0,0.7,0.3,0.7,0.6v14.7
				C52.1,178.7,51.8,179,51.5,179z"
        />
        <path
          style="fill: #404040"
          d="M85.5,179c-0.4,0-0.7-0.3-0.7-0.7v-14.7c0-0.4,0.3-0.6,0.7-0.6c0.4,0,0.7,0.3,0.7,0.6v14.7
				C86.1,178.7,85.8,179,85.5,179z"
        />
        <g id="XMLID_7_">
          <g>
            <path
              style="fill: #d3ae84"
              d="M84.3,162.1l-14.9,15.1l0,0L54,161.8c10.1-8.6,5.9-25.1,5.9-25.1l9.1,0.1l9.1-0.1
						C78,136.7,73.6,153.6,84.3,162.1z"
            />
            <path
              style="fill: #f5f5f5"
              d="M85.8,163.2l-0.1,5.9l-5.1,13.7l-11.2-5.5l0,0l14.9-15.1C84.8,162.5,85.3,162.9,85.8,163.2z"
            />
            <path
              style="fill: #f5f5f5"
              d="M54,161.8l15.4,15.5l-11.2,5.5L53,169l-0.1-6.5C53.3,162.3,53.7,162,54,161.8z"
            />
            <path
              style="fill: #333333"
              d="M85.7,169.1l0.1-5.9c0.3,0.2,0.6,0.4,1,0.6l10.7,2.6c3.2,1,5.6,3.6,6.3,6.9l4.2,26.3l-22.5-0.3
						v0L85.7,169.1z"
            />
            <polygon
              style="fill: #ececec"
              points="69.4,177.2 80.6,182.8 85.7,169.1 85.4,199.3 85.4,199.3 68.6,199.1 53.4,199.3 53,169 
						58.2,182.8 69.3,177.2 69.4,177.2 					"
            />
            <path
              style="fill: #333333"
              d="M53,169l0.3,30.3l-22.6,0.3l4.2-26.3c0.7-3.3,3.1-5.9,6.3-6.9l9.8-2.6c0.7-0.4,1.3-0.8,1.9-1.2
						L53,169z"
            />
          </g>
          <g> </g>
        </g>
        <path
          style="fill: #edc593"
          d="M68.6,97.2c7.3,0,18.6,5.9,19,22.2c0,0-3.2,19-4.6,22.3c-1.1,2.8-6.6,8.4-11.6,11.2
				c-1.2,0.6-4.3,0.6-5.5,0c-5-2.8-10.6-8.4-11.7-11.2c-1.3-3.3-4.6-22.3-4.6-22.3C50,103,61.3,97.2,68.6,97.2z"
        />
        <path
          style="fill: #3b79af"
          d="M84.7,121.2L84.7,121.2c0,0-3.2-0.3-4.7-0.5c-1.6-0.1-4.1-0.3-7.4,0.6c-3.4,0.8-4.2,0.8-7.6,0
				c-3.4-0.8-5.9-0.7-7.4-0.6c-1.6,0.1-4.7,0.4-4.7,0.4c-2.5,0-2.2,1.5-2.2,1.5l1.3,1c1.5,0.5,1.3,2.1,1.3,2.1
				c0.7,7.5,7.3,6.3,7.3,6.3c5.5,0,6.5-5.6,7-6.9c0.5-1.3,2.1-1.3,2.6,0c0.5,1.3,1.5,6.9,7,6.9c0,0,6.5,1.2,7.2-6.3
				c0,0-0.1-1.5,1.2-2.1c0,0,0,0,0,0l1.4-1C86.8,122.6,87.2,121.2,84.7,121.2z M59.9,131.5c-2.5,0.2-3.9-0.6-4.8-1.7
				c-1.5-1.9-1.1-4.6-1.1-4.6s0-3.3,2.8-3.8c3.1-0.6,6.7-0.4,8.5,0.8c0.2,0.1,0.3,0.3,0.5,0.4C67.3,124.2,66.7,131.1,59.9,131.5z
				 M82.4,129.8c-0.8,1.1-2.3,1.9-4.8,1.7c-6.8-0.4-7.4-7.3-5.9-8.9c0.1-0.1,0.3-0.3,0.5-0.4c1.8-1.2,5.4-1.4,8.5-0.8
				c2.8,0.5,2.8,3.8,2.8,3.8S83.8,127.9,82.4,129.8z"
        />
        <path
          style="fill: #4c4c4c"
          d="M93.5,144.8c-7.6-8.8,11-50.8-24.9-47.9c-35.9-2.9-17.3,39.1-24.9,47.9
				c-5.1,5.9-1.1,14.6-1.1,14.6s-1.8-5.5,1.2-11.1c1.9-3.5,3.4,4.8,3.7-25.4c0.3-29.3,20.3-23.8,20.3-23.4v0c0,0,0,0,0,0
				c0,0,0,0,0,0v0c2.6-0.3,20.8-5.9,21.1,23.4c0.3,30.2,2.4,22,4.3,25.4c3.1,5.6,1.3,11.1,1.3,11.1S98.6,150.7,93.5,144.8z"
        />
        <path
          style="fill: #4c4c4c"
          d="M68.6,106.6c0,0-10.8-0.1-7.9,8.7c2.5,7.7-4.5,19.5-7.9,20.5c0,0,8.7-7.9,5.9-20.5
				C56.2,104.3,68.6,106.6,68.6,106.6z"
        />
        <path
          style="fill: #d3ae84"
          d="M70.9,136.5c0.5-0.5,1.2-1.5,1.1-3.1c0-0.6-0.5-1-1.1-1c-0.6,0-1,0.5-1,1.1c0,0.7-0.1,1.2-0.4,1.5
				c-0.5,0.4-1.3,0.5-1.6,0.4c-0.6-0.1-1.1,0.3-1.2,0.9c-0.1,0.6,0.3,1.1,0.9,1.2c0,0,0.2,0,0.4,0C68.7,137.5,69.9,137.4,70.9,136.5
				z"
        />
        <path
          style="fill: #4c4c4c"
          d="M94.8,144.2c-4.5-17.1,5.5-51.5-26.2-50.3C36.9,92.7,47,127,42.4,144.2
				c-4.5,17.1,11.6,34.3,11.6,34.3c-11.4-17.8-5-26.5-5-26.5c10.2-8.3,6.5-39.5,11.4-43.3c4.9-3.8,8.1-1.5,8.1-1.5s3.2-2.3,8.1,1.5
				c4.9,3.8,1.2,35,11.4,43.3c0,0,6.4,8.7-5,26.5C83.2,178.4,99.4,161.3,94.8,144.2z"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  computed: {
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
